<template>
<div id="app" class="privacy-policy page-template-default page page-id-3 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbapks.com.pk/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbapks.com.pk/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <!-- <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbapks.com.pk/" aria-current="page">Home</a>
          </li> -->
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbapks.com.pk/download-gbwhatsapp-apk/">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbapks.com.pk/blogs/">Blogs</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbapks.com.pk/gb-whatsapp-old-version/">Old Version</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article id="post-3" class="post-3 page type-page status-publish">
            <div class="inside-article">
              <header class="entry-header" aria-label="Content">
                <h1 class="entry-title" itemprop="headline">
                  About Us
                </h1>
              </header>

              <div class="entry-content" itemprop="text">
                <p>
                  Welcome to <a href="https://gbapks.com.pk/" class="jump-url">gbapks.com.pk</a>, your trusted source for all things related to WhatsApp Mods!
                </p>
                <p>
                  Our mission is to deliver reliable, up-to-date, and comprehensive information about popular WhatsApp Mods, including GB WhatsApp and more. We focus on their features, benefits, installation processes, and potential limitations, empowering our users to make informed decisions about which app best suits their needs.
                </p>
                <h2>What We Offer</h2>
                <ul>
                  <li><strong>In-Depth Reviews: </strong>Detailed evaluations of WhatsApp Mods to help you understand their functionality and usability.</li>
                  <li><strong>Feature Comparisons: </strong>Clear, side-by-side comparisons of WhatsApp Mods versus the official WhatsApp application.</li>
                  <li><strong>Step-by-Step Guides: </strong>Easy-to-follow tutorials for downloading, installing, and troubleshooting WhatsApp Mods.</li>
                  <li><strong>Expert Insights: </strong>Articles on the safety, legality, and ethical considerations of using modified apps.</li>
                </ul>
                <h2>Our Commitment</h2>
                <p>
                  We are not developers of the GB WhatsApp APK or any other WhatsApp Mod. Our role is purely to serve as an educational and informative platform, providing valuable resources to help our audience navigate the world of WhatsApp modifications.
                </p>
              </div>
              <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://gbapks.com.pk/privacy/">Privacy Policy</a>
          |
          <a href="https://gbapks.com.pk/about-us/">About Us</a>
          |
          <a href="https://gbapks.com.pk/contact-us/">Contact Us</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://gbapks.com.pk/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';

export default {
  name: 'Privacy',
  data() {
    return {
      pageName: 'privacy',
    };
  },
  mounted() {
    document.documentElement.lang = 'en';

  },
};
</script>
